import { ApiResponse, apiBase, customGet } from "./utils"

const baseUrl = `${apiBase}/api/acteNormative`

export type ActNormativ = {
  tip: number
  titlu: string
  link: string
  tipRaport?: number
}
export function getAllActeNormative(): Promise<ApiResponse<ActNormativ[]>> {
  return customGet(`${baseUrl}/all`)
}

import React from "react"
import { ActNormativ } from "../../utils/api/acteNormative"
import "./styles.css"
import TabelActe from "./TabelActe"

type PropTypes = {
  items: ActNormativ[]
}

export default function ActeNormative({ items }: PropTypes) {
  return <TabelActe items={items} />
}

import React from "react"
import "./styles.css"
import TabelActe from "./TabelActe"

export default function TabelRapoarte({ items }) {
  return [
    <TabelActe
      tipRaport={0}
      items={items.filter(item => item.tipRaport === 0)}
    />,
    <TabelActe
      tipRaport={1}
      items={items.filter(item => item.tipRaport === 1)}
    />,
    <TabelActe
      tipRaport={2}
      items={items.filter(item => item.tipRaport === 2)}
    />,
  ]
}

import React from "react"
import { Helmet } from "react-helmet"
import Tabs from "../../../components/Tabs"
import TemplatePage from "../../../components/TemplatePage"
import ActeNormative from "../../../components/legislatie/ActeNormative"
import TabelRapoarte from "../../../components/legislatie/Rapoarte"
import {
  ActNormativ,
  getAllActeNormative,
} from "../../../utils/api/acteNormative"

export default function Legislatie({ location }) {
  const [tabValue, setTabValue] = React.useState(2)
  const [items, setItems] = React.useState<ActNormativ[]>([])

  React.useEffect(() => {
    const getActe = async () => {
      try {
        const apiResponse = await getAllActeNormative()
        if (apiResponse.type) {
          setItems(apiResponse.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getActe()
  }, [])

  return (
    <TemplatePage
      additionalClassName="templateFullContainerFaqScreen"
      location={location}
      title="Legislație"
      topPaneHeight={80 + 56}
      additionalTopPaneElement={
        <Tabs
          setTabValue={setTabValue}
          tabValue={tabValue}
          tabs={["Acte normative", "Rapoarte", "Proceduri"]}
        />
      }
    >
      <Helmet>
        <title>Proceduri - Gazmir</title>
        <meta
          name="description"
          content="Proceduri Gazmir - Care sunt etapele si documentele necesare pentru Schimbarea furnizorului, Acord acces, Modalitati de facturare si plata, Revizii / V.T.P."
        />
        <meta
          name="keywords"
          content="Proceduri Gazmir,Schimbare furnizor gazmir,Proceduri operationale gazmir,Gazmir declaratie acciza,Solutionare dispute gazmir,Gazmir solutionare dispute,Procedura revizie gazmir,Procedura revizii tehnice gazmir,Procedura verificare gazmir,Procedura verificari tehnice gazmir,Verificare iu gazmir,Revizie iu gazmir,revizie instalatie de utilizare gazmir,Procedura mentenanta gazmir"
        />
      </Helmet>
      <div>
        {tabValue === 0 && (
          <ActeNormative items={items.filter(item => item.tip === 0)} />
        )}
        {tabValue === 1 && (
          <TabelRapoarte items={items.filter(item => item.tip === 1)} />
        )}
        {tabValue === 2 && (
          <ActeNormative items={items.filter(item => item.tip === 2)} />
        )}
      </div>
    </TemplatePage>
  )
}
